<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 mb-3">
                <span class="badge fondo-secundario-s" v-for="(item, index) of noticia?.tags" v-bind:key="index">
                    {{ item.nombre }}
                </span>
            </div>
            <div class="col-12 mb-4">
                <span class="text-muted">Modificación: {{ timeReste(noticia?.fecha) }}</span>
            </div>
            <div class="col-10">
                <div class="row justify-content-center">
                    <div class="col-10">
                        <div class="row">
                            <div class="col-12 mb-4">
                                <h1 class="mb-4">{{ noticia?.titulo }}</h1>
                                <p>{{ noticia?.subtitulo }}</p>
                                <img class="w-100" :src="BASE_URL + noticia?.portada.url" alt="">
                            </div>
                            <VueShowdown :markdown="noticia?.contenido" flavor="github" :options="{ emoji: true }" />
                        </div>
                    </div>
                    <div class="col-1">
                        <div class="row justify-content-end position-sticky" style="top: 8rem;">
                            <ul style="list-style: none;">
                                <li>
                                    <a href="javascript: void(0);" class="btn mb-1" v-on:click="sharedFacebook(noticia)">
                                        <i class="icon-facebook2"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript: void(0);" class="btn mb-1" v-on:click="sharedTwitter(noticia)">
                                        <i class="icon-twitter"></i>
                                    </a>
                                </li>
                            </ul>
                            <span class="texto-compartir">Compartir con</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { VueShowdown } from 'vue-showdown';
export default {
    name: 'Noticias',
    created() {
        this.ApiNoticias();
    },
    props: ['idNoticia'],
    data: function(){
        return {
            noticia: null
        }
    },
    computed: {
        ...mapState(['BASE_URL','redesSociales'])
    },
    methods: {
        async ApiNoticias() {
            await axios({
                    method: 'GET',
                    url: `https://noticias.terminaldesantamarta.com/noticias/${this.idNoticia}`,
                    responseType: 'json',
                })
                .then(res => {
                    this.noticia = res.data;
                }).catch(err => console.err(err));
        },
        timeReste(time){
            const dateTime = new Date(time);
            let dia = dateTime.getDate();
            let mes = dateTime.getMonth();
            let hora = dateTime.getHours();
            let minutos = dateTime.getMinutes();
            console.log(dateTime);
            console.log(dateTime.getMonth()+1);
            if(dateTime.getDate() < 10){
                dia = '0'+dateTime.getDate()
            }
            if(dateTime.getMonth()+1 < 10){
                mes = '0'+dateTime.getMonth()+1
            }
            if(dateTime.getHours() < 10){
                hora = '0'+dateTime.getHours()
            }
            if(dateTime.getMinutes() < 10){
                minutos = '0'+dateTime.getMinutes()
            }
            return `${dia}-${mes}-${dateTime.getFullYear()} hora: ${hora}:${minutos}`;
        },
        sharedFacebook(item){
            const url = 'http://www.facebook.com/sharer.php?u=' + this.BASE_URL + '/' + item.id + '&t=' + item.titulo;
            window.open(url,'ventanacompartir', 'toolbar=0, status=0, width=650, height=450');
        },
        sharedTwitter(item){
            const url = 'http://twitter.com/home?status=' + this.BASE_URL + '/' + item.id + '&t=' + item.titulo;
            window.open(url,'ventanacompartir', 'toolbar=0, status=0, width=650, height=450');
        }
    },
    components: {
        VueShowdown
    }
}
</script>

<style lang="css">
.badge {
    padding: 10px 20px;
    border-radius: 10rem;
    margin-right: .5rem;
}

.texto-compartir {
    position: absolute;
    right: -70px;
    top: 3rem;
    transform: rotate(90deg);
}

.textoNoticia .img-grupo img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
}
</style>