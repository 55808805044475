<template src="@/views/noticias/noticias-template.vue"></template>
<style src="@/views/noticias/noticias.css"></style>

<script>
import Inicio from '@/components/noticias/Inicio.vue'
import Noticia from '@/components/noticias/Noticia.vue'
export default {
    name: 'noticias',
    data() {
        return {

        }
    },
    components: {
        Inicio,
        Noticia
    }
}
</script>