<template>
    <!-- res -->
    <div class="row mb-5">
        <div class="col-lg-7 col-12">
            <article class="card noticia mb-3">
                <div class="card-imagen position-relative">
                    <img class="card-img-top" :src="BASE_URL + noticia?.portada.url" alt="Card image cap">
                    <div class="titulo position-absolute" style="bottom: 1rem; left: 1rem;">
                        <div class="d-block tema-principal px-4 py-2 text-uppercase font-weight-bold h4 m-0">
                            <router-link :to="'/noticias/'+noticia?.id">{{ noticia?.titulo }}</router-link>
                        </div>
                        <div class="d-inline-block tema-secundario px-2">
                            <time :datetime="noticia?.fecha">{{ timeReste(noticia?.fecha) }}</time>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <p class="card-text">{{ noticia?.subtitulo }}</p>
                </div>
            </article>
        </div>
        <div class="col-lg-5 col-12">
            <div class="row">
                <Title title="Noticias destacadas"></Title>
                <router-link :to="'/noticias/'+item.id" class="col-12 tarejta-noticia mb-4 fondo-opaco-1 pl-0" v-for="(item, index) of noticias" v-bind:key="index">
                    <div class="row align-items-center">
                        <div class="col-4">
                            <img :src="BASE_URL + item?.portada.url" alt="" class="w-100">
                        </div>
                        <div class="col">
                            <div class="d-block text-uppercase font-weight-bold color-principal mb-2 small">
                                {{ item?.titulo }}
                            </div>
                            <time :datetime="item?.fecha">{{ timeReste(item?.fecha) }}</time>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import Title from '@/components/global/Title.vue';
import axios from 'axios';
import { mapState } from 'vuex';

export default {
    name: 'Destacadas',
    created() {
        this.ApiNoticias();
        this.ApiUnaNoticia();
    },
    data() {
        return {
            noticia: null,
            noticias: [],
        }
    },
    computed: {
        ...mapState(['BASE_URL'])
    },
    methods: {
        async ApiNoticias() {
            await axios({
                    method: 'GET',
                    url: `https://noticias.terminaldesantamarta.com/noticias?_limit=3&_sort=fecha:DESC&_publicationState=live&_destacar=true`,
                    responseType: 'json',
                })
                .then(res => {
                    this.noticias = res.data;
                }).catch(err => console.err(err));
        },
        ApiUnaNoticia() {
            axios({
                    method: 'GET',
                    url: `https://noticias.terminaldesantamarta.com/noticias?_limit=1&_sort=fecha:DESC&_publicationState=live&_destacar=true`,
                    responseType: 'json',
                })
                .then(res => {
                    this.noticia = res.data[0];
                }).catch(err => console.log(err));
        },
        timeReste(time){
            const dateTime = new Date(time);
            let dia = dateTime.getDate();
            let mes = dateTime.getMonth()+1;
            let hora = dateTime.getHours();
            let minutos = dateTime.getMinutes();

            if(dateTime.getDate() < 10){
                dia = '0'+dateTime.getDate()
            }
            if(dateTime.getMonth()+1 < 10){
                mes = '0'+dateTime.getMonth()
            }
            if(dateTime.getHours() < 10){
                hora = '0'+dateTime.getHours()
            }
            if(dateTime.getMinutes() < 10){
                minutos = '0'+dateTime.getMinutes()
            }

            return `${dia}-${mes}-${dateTime.getFullYear()} hora: ${hora}:${minutos}`;
        }
    },
    components: {
        Title
    }
}
</script>
<style>
.noticia .card-imagen img {
    height: 350px;
    object-fit: cover;
}

a.tarejta-noticia {
    text-decoration: none !important;
}

.tarejta-noticia img {
    height: 100px;
    object-fit: cover;
}

.noticia .tema-principal {
    max-width: 80%;
}

.noticia .tema-principal a {
    color: #fff;
}
</style>
