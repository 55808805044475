<template>
    <div class="container-fluid">
        <Destacadas></Destacadas>
        <div class="row">
            <router-link :to="'/noticias/'+item.id" class="col-12 col-md-4 mb-4 tarjeta-noticia" v-for="(item, index) of noticias" v-bind:key="index" v-on:click="scrollToTop">
                <img :src="item.img" alt="" class="w-100 img-card">
                <div class="d-block pt-3">
                    <h5 class="text-uppercase mb-3">{{ item.titulo }}</h5>
                    <p class="d-block h6">{{ item.desc }}</p>
                    <time class="text-muted" datetime="2011-01-12">{{ item.fecha }}</time>
                </div>
            </router-link>
            <div class="col-12 mt-4">
                <button class="btn w-100 btn-primary" v-on:click="loadMoreNoticias">Cargar mas</button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import Destacadas from '@/components/noticias/Destacadas.vue'
export default {
    name: 'Inicio',
    created() {
        this.ApiNoticias();
    },
    data() {
        return {
            noticias: [],
            quantity: 9,
        }
    },
    methods: {
        ApiNoticias() {
            axios({
                    method: 'GET',
                    url: `https://noticias.terminaldesantamarta.com/noticias?_sort=fecha:DESC&_limit=${this.quantity}`,
                    responseType: 'json',
                })
                .then(res => {
                    this.noticias = res.data.map(item => {
                        return {
                            id: item.id,
                            titulo: item.titulo,
                            desc: item.subtitulo,
                            fecha: item.published_at,
                            img: 'https://noticias.terminaldesantamarta.com' + item.portada.url
                        }
                    })
                }).catch(err => console.log(err));
        },
        loadMoreNoticias(){
            this.quantity = this.quantity + 6;
            this.ApiNoticias();
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    },
    components: {
        Destacadas
    }
}
</script>
<style>
.tarjeta-noticia {
    color: #333;
    text-decoration: none !important;
}
.tarjeta-noticia p {
    color: #7B7B7B;
}
.tarjeta-noticia:hover img {
    box-shadow: 0 5px 15px 1px rgba(0, 0, 0, .15);
}
.img-card {
    height: 220px;
    object-fit: cover;
    object-position: top;
}
</style>