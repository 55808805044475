<template>
    <div class="titulo-destacado d-block mb-3">
        <div class="titulos d-block">
            <h5 class="font-weight-bold text-uppercase color-principal">{{ title }}</h5>
        </div>
        <div class="decoracion">
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Title',
    props: ['title']
}
</script>